import {
    camelCase,
    cloneDeep,
    startCase
}                               from "lodash";
import { defineAsyncComponent } from "vue";

import ComponentErrorComponent   from "@/components/ComponentErrorComponent.vue";
import ComponentLoadingComponent from "@/components/ComponentLoadingComponent.vue";
import { getFields }             from "@/shared/EditorDefinitions.js";
import { useContentBlockStore }  from "@/shared/Stores/ContentBlockStore.js";
import { showError }             from "@/shared/utils.js";

export const snakeToPascal = (string) => {
    return startCase(camelCase(string)).replace(/ /g, "");
};

const contentBlockComponents = import.meta.glob("@/components/ContentBlocks/**/*.vue");
let cacheEditorContentBlock  = {};

/**
 * Fields van definitie mergen met waarden van item
 */
export function mergeFields(item) {
    let definition  = cloneDeep(getFields(item, false));
    const cbStore   = useContentBlockStore();
    let itemFields  = contentBlockFieldsToObject(item);
    let itemOptions = contentBlockFieldsToObject(item, "options", true);

    let type       = itemFields.type;
    let cbStoreDef = cbStore.contentBlocks[type];

    if (cbStoreDef) {
        if (cbStoreDef.description && definition.description) {
            definition.description.value = cbStoreDef.description;
        }
    }

    for (const key in definition) {
        if (key === "description") {
            // Only apply if not already set
            if (!definition[key].value) {
                definition[key].value = itemFields[key];
                if (itemOptions[key]) {
                    definition[key].options = itemOptions[key];
                }
            }
        }
        if (itemFields[key]) {
            definition[key].value = itemFields[key];
        }
        if (itemOptions[key]) {
            definition[key].options = itemOptions[key];
        }
    }

    return Object.values(definition);
}

export function loadEditorContentBlock(component, errorOnFail = true) {
    if (cacheEditorContentBlock[component]) {
        return cacheEditorContentBlock[component];
    }

    let snakeToPascalComponent = snakeToPascal(component);

    let components = Object.keys(contentBlockComponents).filter(path =>
        path.endsWith(`${snakeToPascalComponent}Block.vue`)
    );
    
    let componentImportFn = "";
    if(components.length > 1){
        componentImportFn = components.reduce((a, b) => (a.length <= b.length ? a : b));     
    } else {
        componentImportFn = components[0];        
    }


    let loader = () => import("@/components/ContentBlocks/DefaultContentBlock.vue");

    if (!componentImportFn) {
        if (errorOnFail || [
            "test",
            "dev",
            "APPVERSION",
        ].indexOf(__VERSION_NUMBER__) > -1) {
            showError(`Component ${snakeToPascalComponent} not found`);
        }
    } else {
        loader = contentBlockComponents[componentImportFn];
    }

    return cacheEditorContentBlock[component] = defineAsyncComponent({
        loader:           loader,
        loadingComponent: ComponentLoadingComponent,
        errorComponent:   ComponentErrorComponent,
        delay:            200,
        timeout:          3000,
    });
}

export const readContentBlockField = (block, key) => {
    if (block.fields[key]) {
        return block.fields[key].value || block.fields[key].options || null;
    }
    for (let i = 0; i < block.fields.length; i++) {
        if (block.fields[i].key === key) {
            return block.fields[i].value
                || block.fields[i].options
                || null;
        }
    }

    return null;
};

export const contentBlockFieldsToObject = (block, objectKey = "value", deleteEmpty = false) => {
    let o = {};

    if (!block?.fields) {
        return o;
    }

    if (Array.isArray(block.fields)) {
        for (let i = 0; i < block.fields.length; i++) {
            if (block?.fields[i]?.key) {
                o[block.fields[i]?.key] = block.fields[i]?.[objectKey] || null;
            }
        }
    } else if (typeof (block.fields) == "object") {
        for (const key in block.fields) {
            o[key] = block.fields[key]?.[objectKey] || null;
        }
    }

    if (deleteEmpty) {
        for (const key in o) {
            if (o[key] === null || !o[key] || !o[key].length) {
                delete o[key];
            }
        }
    }

    return o;
};

export const filterParentFields = (fields) => {
    return fields.filter(field => (!!field.parent));
};

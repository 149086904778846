import { defineStore } from "pinia";

import apiService from "@/shared/Services/ApiService.js";
import { mtm }    from "@/shared/utils.js";

const {
    VITE_APP_CLIENT_ID:     client_id,
    VITE_APP_CLIENT_SECRET: client_secret,
    VITE_APP_AUTH_REDIRECT: redirect_uri,
    VITE_APP_API_URL:       host_url,
} = import.meta.env;

const getRedirectUrl = () => {
    const authorizationUrl = `${host_url}/oauth/authorize`;

    // Construct the authorization URL
    const queryParams = {
        client_id:     client_id,
        redirect_uri:  redirect_uri,
        response_type: "code",
        scope:         "",
        state:         [ ...crypto.getRandomValues(new Uint8Array(32)) ]
            .map(b => b.toString(16)
                .padStart(2, "0"))
            .join(""),
    };

    return `${authorizationUrl}?${new URLSearchParams(queryParams)}`;
};

export const useAuthStore = defineStore("auth", {
    state:   () => ({
        accessToken:  null,
        refreshToken: null,
        user:         null,
        initialized:  false,
    }),
    getters: {
        getAccessToken:  state => state.accessToken,
        getRefreshToken: state => state.refreshToken,
        getUser:         state => state.user,
        isLoggedIn:      (state) => {
            if (!state.user) {
                return false;
            }
            if (!state.accessToken) {
                return false;
            }
            return true;
        },
        getUserRole:     (state) => {
            if (state.user && state.user.role) {
                return state.user.role;
            }
            return {
                "uuid":       "fb1f9c08-b568-4ded-bde9-84fde4bba4de",
                "deleted_at": null,
                "created_at": "2024-06-19T16:42:16.000000Z",
                "updated_at": "2024-07-11T12:53:01.000000Z",
                "name":       "Gast",
                "level":      "gast",
                "standard":   true,
                "def":        "[]",
            };
        },
        getRedirectUrl:  () => getRedirectUrl(),
    },
    actions: {
        setAccessToken(token) {
            this.accessToken = token;
        },
        setRefreshToken(token) {
            this.refreshToken = token;
        },
        setInitialized(value) {
            this.initialized = value;
        },
        setUser(user) {
            //this.user = new ProfileEntity(user);
            this.user = user;
            if (this.user && this.user.uuid) {
                mtm("setUserId", this.user.uuid);

                mtm("setCustomDimension", 1, `${this.user.first_name} ${this.user.name}`);
                mtm("setCustomDimension", 2, this.getUserRole.name);
                mtm("setCustomDimension", 3, this.user.email);

                mtm("trackPageView");
            }
        },
        redirect() {
            window.location.href = getRedirectUrl();
        },
        fetchToken(code) {
            return new Promise((resolve, reject) => {
                // Exchange authorization code for an access token
                apiService.post("/oauth/token", {}, {
                    grant_type:    "authorization_code",
                    client_id:     client_id,
                    client_secret: client_secret,
                    redirect_uri:  redirect_uri,
                    code,
                })
                    .then(({ data }) => {
                        if (data.access_token && data.refresh_token) {
                            this.setAccessToken(data.access_token);
                            this.setRefreshToken(data.refresh_token);
                            resolve();
                        } else {
                            throw new Error("No token data returned");
                        }
                    })
                    .catch((error) => {
                        this.clearTokens();
                        reject(error);
                    }).finally(() => this.setInitialized(true));
            });
        },
        exchangeImpersonate(code) {
            return new Promise((resolve, reject) => {
                apiService.post(`/api/v1/impersonate/code/${code}`)
                    .then(({ data }) => {
                        if (data.access_token && data.refresh_token) {
                            this.setAccessToken(data.access_token);
                            this.setRefreshToken(data.refresh_token);
                            resolve();
                        } else {
                            throw new Error("No token data returned");
                        }
                    })
                    .catch((error) => {
                        this.clearTokens();
                        reject(error);
                    }).finally(() => this.setInitialized(true));
            });
        },
        fetchUser() {
            return new Promise((resolve, reject) => {
                apiService.get("/api/v1/users/me", {
                    with: "role,leerbedrijven,onderwijsinstellingen,stagedocenten,praktijkopleiders,leerlingen,pending",
                }).then(({ data }) => {
                    this.setUser(data[0]);
                    resolve();
                }).catch(error => reject(error)).finally(() => this.setInitialized(true));
            });
        },
        logout() {
            return new Promise((resolve) => {
                apiService.post("/oauth/invalidate").catch(() => {}).finally(() => {
                    this.clearTokens();
                    resolve();
                });
            });
        },
        checkIfTokenIsValid() {
            return new Promise((resolve, reject) => {
                this.fetchUser().then(() => {
                    this.setInitialized(true);
                    resolve();
                }).catch((error) => {
                    // Check error status code
                    if (error.status === 401) {
                        this.clearTokens();
                        reject(error);
                    } else {
                        throw new Error(error);
                    }
                });
            });
        },
        clearTokens() {
            this.setAccessToken(null);
            this.setRefreshToken(null);
            this.setUser(null);
        },
        register(data) {
            return new Promise((resolve, reject) => {
                apiService.post("/api/v1/users/register", [], data).then(({ data }) => {
                    this.setUser(data);
                    resolve();
                }).catch(error => reject(error));
            });
        },
        updateUser(user) {
            return new Promise((resolve, reject) => {
                apiService.put("/api/v1/users/me", [], user).then(({ data }) => {
                    this.setUser(data);
                    resolve();
                }).catch(error => reject(error));
            });
        },
        updateUserPassword(user) {
            return new Promise((resolve, reject) => {
                apiService.put("/api/v1/users/me/password", [], user).then(({ data }) => {
                    resolve(data);
                }).catch(error => reject(error));
            });
        },
        isDirectRelated() {
            const role = this.user.role.level;
            return role == "stagedocent" || role == "praktijkopleider";
        },
    },
    persist: {
        pick:         [
            "accessToken",
            "refreshToken",
            "user",
            "initialized",
        ],
        afterHydrate: (ctx) => {
            if (ctx.store.user) {
                ctx.store.setUser(ctx.store.user);
            }
        },
    },
});

<template>
    <div v-if="user && user.original_user_id" class="bg-primary text-white p-2 text-center">
        {{ user?.original_user?.first_name }} {{ user?.original_user?.name }} ({{ user?.original_user?.email }}) ingelogd als {{ user?.first_name }} {{ user?.name }} ({{ user?.email }})!
    </div>
    <header class="container custom-container">
        <div class="row justify-content-between">
            <div class="col d-block">
                <h2 rel="app_name">
                    <smarty-router-link :to="{name: 'dashboard'}">
                        {{ $APP_NAME }}
                    </smarty-router-link>
                </h2>
            </div>
            <div v-if="user" class="col text-right">
                <div class="user-profile icons">
                    <smarty-router-link
                        :to="{name: 'dashboard'}"
                        class="user-info"
                    >
                        <div class="profile-pic">
                            <i class="bi bi-person"></i>
                        </div>
                        <div class="profile-data">
                            <span class="username">{{ user.first_name }} {{ user.name }}</span>
                            <span class="role">{{ user.role.name }}</span>
                        </div>
                    </smarty-router-link>
                    <smarty-router-link
                        :to="inboxRouteBasedOnRule()"
                        class="notifications"
                    >
                        <i class="bi bi-envelope"></i>
                        <span v-if="counter > 0 && !isLoading" class="counter">{{ counter }}</span>
                        <template v-if="isLoading">
                            <span class="spinner-border spinner-border-sm spinner-counter text-primary"></span>
                        </template>
                    </smarty-router-link>
                    <smarty-router-link
                        :to="{name: 'dashboard'}"
                        class="notifications"
                    >
                        <i class="bi bi-house"></i>
                    </smarty-router-link>
                </div>
            </div>
            <div v-else class="col text-right">
                <smarty-router-link :to="{ name: 'faq' }" class="btn btn-success header-link">
                    FAQ
                </smarty-router-link>
                <smarty-router-link :to="{ name: 'login' }" class="btn btn-primary header-link">
                    Inloggen
                </smarty-router-link>
                <smarty-router-link :to="{ name: 'register' }" class="btn btn-primary header-link">
                    Registreer
                </smarty-router-link>
            </div>
        </div>
    </header>
</template>

<script>
    import {
        onMounted,
        ref,
        watch
    }                   from "vue";
    import { useRoute } from "vue-router";

    import SmartyRouterLink   from "@/components/global/SmartyRouterLink.vue";
    import ApiService         from "@/shared/Services/ApiService.js";
    import { useAuthStore }   from "@/shared/Stores/AuthStore";
    import { showError }      from "@/shared/utils.js";
    import { useInboxStore }  from "@/stores/modules/inboxStore";

    export default {
        name:       "AppHeader",
        components: {
            SmartyRouterLink,
        },
        setup() {
            const inboxStore        = useInboxStore();
            const authStore         = useAuthStore();
            const route             = useRoute();
            const isLogin           = (route.fullPath.indexOf("login") > 0) ? 1 : 0;
            const user              = ref(null);
            const showBeoordelingen = ref(null);
            const counter           = ref(0);
            const isLoading         = ref(false);

            const isCurrentRoute = (routePath) => {
                return route.path === routePath;
            };

            const getInbox = () => {
                if(!inboxStore.isFilled){
                    isLoading.value = true;
                } else {
                    counter.value = inboxStore.getInboxCounter;
                }

                ApiService.get("/api/v1/inbox", {
                    type:  "Counter",
                })
                    .catch((e) => {
                        showError(e);
                        isLoading.value = false;
                    })
                    .then(({ data }) => {
                        isLoading.value = false;
                        inboxStore.setInboxCounter(data.total);
                        inboxStore.setUitnodigingenCounter(data.uitnodigingen);
                        inboxStore.setBeoordelingenCounter(data.beoordelingen);
                        inboxStore.setBedrijfAanvragenCounterCounter(data.bedrijf_aanvragen);
                        inboxStore.setLeerlingenAavragenCounter(data.leerling_aanvragen);
                        counter.value = inboxStore.getInboxCounter;
                    });
            };

            const inboxRouteBasedOnRule = () => {
                const role = authStore.getUserRole.level;

                if(role === "leerling"){
                    return {
                        name: "inbox",
                        hash: `#Uitnodigingen`,
                    };
                }

                if([
                    "praktijkopleider",
                    "stagedocent",
                ].includes(role)){
                    return {
                        name: "inbox",
                        hash: `#Beoordelingsverzoeken`,
                    };
                }

                if(role === "manager"){
                    return {
                        name: "inbox",
                        hash: `#Uitnodigingen`,
                    };
                }

                return { name: "inbox" };
            };

            watch(() => inboxStore.getInboxCounter, (newValue) => {
                counter.value = newValue;
            });

            onMounted(() => {
                if (authStore.getUser) {
                    user.value              = authStore.getUser;
                    showBeoordelingen.value = user.value.role.level !== "leerling";
                    getInbox();
                }
            });

            return {
                route,
                isLogin,
                isCurrentRoute,
                user,
                counter,
                showBeoordelingen,
                isLoading,
                inboxRouteBasedOnRule,
            };
        },
    };
</script>

<style lang="scss" scoped>
    header {
        padding-top: 25px;
        padding-bottom: 25px;

        .row {
            .col {
                &:last-child {
                    text-align: right;
                }

                &.text-right {
                    text-align: right;

                    > span {
                        margin-left: 15px;
                    }
                }

                .icons {
                    > span {
                        margin-left: 15px;
                    }
                }
            }
        }

        .header-link {
            + .header-link {
                margin-left: 15px;
            }
        }

        h2 {
            &[rel="app_name"] {
                a {
                    text-decoration: none;
                }
            }
        }
    }
</style>

import { defineStore } from "pinia";

export const useInboxStore = defineStore("inboxStore", {
    state:   () => ({
        inboxCounter: null,
        UitnodigingenCounter: null,
        beoordelingenCounter: null,
        bedrijfAanvragenCounter: null,
        LeerlingAanvragenCounter: null,
    }),
    getters: {
        getInboxCounter: state => state.inboxCounter,
        getUitnodigingenCounter: state => state.UitnodigingenCounter,
        getBeoordelingenCounter: state => state.beoordelingenCounter,
        getBedrijfAanvragenCounter: state => state.bedrijfAanvragenCounter,
        getLeerlingenAavragenCounter: state => state.LeerlingAanvragenCounter,
        isFilled: (state) => {
            return state.inboxCounter !== null && 
                   state.UitnodigingenCounter !== null && 
                   state.beoordelingenCounter !== null &&
                   state.bedrijfAanvragenCounter !== null &&
                   state.LeerlingAanvragenCounter !== null;
        },
    },
    actions: {
        setInboxCounter(value) {
            this.inboxCounter = value;
        },
        setUitnodigingenCounter(value) {
            this.UitnodigingenCounter = value;
        },
        setBeoordelingenCounter(value) {
            this.beoordelingenCounter = value;
        },
        setBedrijfAanvragenCounterCounter(value) {
            this.bedrijfAanvragenCounter = value;
        },
        setLeerlingenAavragenCounter(value) {
            this.LeerlingAanvragenCounter = value;
        },
    },
    persist: {
        enabled: false,
    },
});
